import React from 'react';

const CustomEvent = ({ event, services }) => {
  const { clientName, serviceId } = event;
  const service = services?.find((service) => service.id === serviceId);

  return (
    <div style={{
      padding: '4px',
      fontSize: '14px',
      fontWeight: 'bold',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    }}>
      {clientName} - {service ? service.name : ''}
    </div>
  );
};

export default CustomEvent;
