import React, { useState } from 'react';
import { Box, Button, TextField, Typography, Paper, Avatar, Container, CssBaseline } from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { useNavigate } from 'react-router-dom';
import bcrypt from 'bcryptjs';

const Register = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [displayName, setDisplayName] = useState('');
  const [emailAddress, setEmailAddress] = useState('');
  const navigate = useNavigate();

  const handleRegister = async () => {
    try {
      console.log('Registering with username:', username, 'and password:', password, 'and email address:', emailAddress);
      if (!username || !password || !emailAddress) {
        alert('Please fill in all required fields.');
        return;
      }
      console.log("process.env.REACT_APP_HASH_SALT", `$2a$10$.${process.env.REACT_APP_HASH_SALT}`)
      const hashedPassword = await bcrypt.hash(password, `$2a$10$.${process.env.REACT_APP_HASH_SALT}`);

      const response = await fetch(`${process.env.REACT_APP_IDENTITY_API_URL}/auth/wld/signup`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          username: username,
          password: hashedPassword,
          displayName: displayName || username,
          email: emailAddress
        }), // Convert the username and password to a JSON string
      });

      if (!response.ok) {
        // If the response was not OK, throw an error
        throw new Error(`Error: ${response.statusText}`);
      }

      const data = await response.json(); // Parse the JSON response
      console.log('Login successful:', data);

      // Handle the successful login, e.g., store the token, navigate, etc.
      localStorage.setItem('isAuthenticated', 'true');
      localStorage.setItem('token', data.access_token)
      navigate('/');
    } catch (error) {
      console.error('Registration failed:', error);
      // Handle the error, e.g., show an error message
      alert('Registration failed!');
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Paper sx={{ mt: 8, p: 4, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Register
        </Typography>
        <Box component="form" sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            name="email address"
            label="Email Address"
            id="emailAddress"
            autoComplete="email address"
            value={emailAddress}
            onChange={(e) => setEmailAddress(e.target.value)}
          />
          <TextField
            margin="normal"
            fullWidth
            id="displayName"
            label="Display Name"
            name="display Name"
            autoComplete="displayName"
            autoFocus
            value={displayName}
            onChange={(e) => setDisplayName(e.target.value)}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="username"
            label="Username"
            name="username"
            autoComplete="username"
            autoFocus
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <Button
            type="button"
            fullWidth
            variant="contained"
            color="primary"
            sx={{ mt: 3, mb: 2 }}
            onClick={handleRegister}
          >
            Register
          </Button>
        </Box>
      </Paper>
    </Container>
  );
};

export default Register;
