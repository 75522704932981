import React from 'react';
import { Snackbar } from '@mui/material';

const EventSnackbar = ({ open, message, onClose }) => (
  <Snackbar
    open={open}
    autoHideDuration={3000}
    onClose={onClose}
    message={message}
  />
);

export default EventSnackbar;
