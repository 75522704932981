import React, { useState } from 'react';
import { Box, Typography, Tabs, Tab, TextField, Button } from '@mui/material';
import WorkingTemplateForm from '../components/WorkingTemplateForm'; // Adjust the import path as needed

const Preferences = () => {
  const [selectedTab, setSelectedTab] = useState(0);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h4" gutterBottom>Preferences</Typography>

      <Tabs
        value={selectedTab}
        onChange={handleTabChange}
        indicatorColor="primary"
        textColor="primary"
        variant="fullWidth"
      >
        <Tab label="General" />
        <Tab label="Working Template" />
      </Tabs>

      <Box sx={{ mt: 4 }}>
        {selectedTab === 0 && (
          <Box>
            <TextField
              label="Email"
              value=""
              fullWidth
              margin="normal"
              // Add your state handling for the email field here
            />
            <Button
              variant="contained"
              color="primary"
              // Add your save logic here
            >
              Save Preferences
            </Button>
          </Box>
        )}
        {selectedTab === 1 && (
          <Box>
            <WorkingTemplateForm onSave={(template) => console.log('Template saved:', template)} />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default Preferences;
