import React, { useState, useEffect } from 'react';
import { Box, Typography, TextField, MenuItem, Button, Grid, Card, CardContent } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';

const data = [
  {
    id: 1,
    name: 'Haircut',
    professionals: [
      { id: 1, name: 'John Doe', price: 25, duration: 60 },
      { id: 2, name: 'Jane Smith', price: 30, duration: 60 },
    ],
  },
  {
    id: 2,
    name: 'Manicure',
    professionals: [
      { id: 3, name: 'Alice Johnson', price: 22, duration: 45 },
    ],
  },
  {
    id: 3,
    name: 'Massage',
    professionals: [
      { id: 1, name: 'John Doe', price: 50, duration: 90 },
      { id: 3, name: 'Alice Johnson', price: 55, duration: 90 },
    ],
  },
];

const fetchServices = () => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(data.map(service => ({ id: service.id, name: service.name })));
    }, 1000);
  });
};

const fetchProfessionals = (serviceId, date) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      const service = data.find(service => service.id === serviceId);
      resolve(service ? service.professionals : []);
    }, 1000);
  });
};

const fetchAvailableTimeSlots = (professionals, date) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      const timeSlots = professionals.flatMap(pro =>
        ['09:00 - 10:00', '12:30 - 13:15'].map(timeSlot => ({
          professional: pro.name,
          slot: timeSlot,
          price: pro.price,
          duration: pro.duration,
        }))
      );
      resolve(timeSlots);
    }, 1000);
  });
};

const fetchNextAvailableSlots = () => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve([
        {
          professional: 'John Doe',
          slots: ['09:00 - 10:00', '10:00 - 11:00'],
          price: 25,
          duration: 60,
          date: '2023-12-25',
        },
      ]);
    }, 1000);
  });
};

const Client = () => {
  const [services, setServices] = useState([]);
  const [professionals, setProfessionals] = useState([]);
  const [service, setService] = useState('');
  const [date, setDate] = useState(dayjs().format('YYYY-MM-DD'));
  const [professional, setProfessional] = useState('');
  const [timeSlots, setTimeSlots] = useState([]);
  const [nextAvailableSlot, setNextAvailableSlot] = useState(null);
  const [selectedSlot, setSelectedSlot] = useState('');

  useEffect(() => {
    const getServices = async () => {
      const services = await fetchServices();
      setServices(services);
    };
    getServices();
  }, []);

  useEffect(() => {
    if (service) {
      const getProfessionals = async () => {
        const professionals = await fetchProfessionals(service, date);
        setProfessionals(professionals);
      };
      getProfessionals();
    }
  }, [service, date]);

  const handleSearch = async () => {
    if (professionals.length === 0) return;

    const selectedProfessional = professional ? professionals.find(pro => pro.id === parseInt(professional)) : null;
    const availableSlots = await fetchAvailableTimeSlots(selectedProfessional ? [selectedProfessional] : professionals, date);

    setTimeSlots(availableSlots);

    if (selectedProfessional && availableSlots.every(slot => slot.slots.length === 0)) {
      const nextAvailableSlots = await fetchNextAvailableSlots();
      setNextAvailableSlot(nextAvailableSlots);
    }
  };

  const handleSlotChange = (slot) => {
    setSelectedSlot(slot);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box sx={{ p: 3 }}>
        <Typography variant="h4" gutterBottom>Book an Appointment</Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <TextField
              select
              label="Service"
              value={service}
              onChange={(e) => setService(e.target.value)}
              fullWidth
              variant="outlined"
            >
              {services.map((service) => (
                <MenuItem key={service.id} value={service.id}>
                  {service.name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} md={4}>
            <DatePicker
              label="Select Date"
              value={dayjs(date)}
              onChange={(newValue) => setDate(newValue.format('YYYY-MM-DD'))}
              renderInput={(params) => <TextField {...params} fullWidth variant="outlined" />}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              select
              label="Professional (Optional)"
              value={professional}
              onChange={(e) => setProfessional(e.target.value)}
              fullWidth
              variant="outlined"
            >
              <MenuItem value="">None</MenuItem>
              {professionals.map((pro) => (
                <MenuItem key={pro.id} value={pro.id}>
                  {pro.name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12}>
            <Button variant="contained" color="primary" onClick={handleSearch}>
              Search Available Time Slots
            </Button>
          </Grid>
        </Grid>
        {timeSlots.length > 0 && (
          <Box sx={{ mt: 3 }}>
            <Typography variant="h5">Available Time Slots</Typography>
            {professionals.map((pro) => (
              <Box key={pro.id} sx={{ mt: 2 }}>
                <Typography variant="h6">{pro.name}</Typography>
                <Grid container spacing={2}>
                  {timeSlots
                    .filter(slot => slot.professional === pro.name)
                    .map((slot, index) => (
                      <Grid item xs={12} md={6} lg={4} key={index}>
                        <Card
                          sx={{
                            backgroundColor: selectedSlot === `${slot.professional}-${slot.slot}` ? 'secondary.main' : 'white',
                            cursor: 'pointer',
                            elevation: 10,
                            border: '1px solid #ccc',
                            width: '90%',
                          }}
                          onClick={() => handleSlotChange(`${slot.professional}-${slot.slot}`)}
                        >
                          <CardContent>
                            <Typography variant="body1">{slot.slot}</Typography>
                          </CardContent>
                        </Card>
                      </Grid>
                    ))}
                </Grid>
              </Box>
            ))}
          </Box>
        )}
        {nextAvailableSlot && (
          <Box sx={{ mt: 3 }}>
            <Typography variant="h5">Next Available Time Slot for Selected Professional</Typography>
            <Typography>Date: {nextAvailableSlot[0].date}</Typography>
            {nextAvailableSlot[0].slots.map((timeSlot, idx) => (
              <Typography key={idx}>{timeSlot}</Typography>
            ))}
          </Box>
        )}
      </Box>
    </LocalizationProvider>
  );
};

export default Client;
