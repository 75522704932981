import React, { useState } from 'react';
import { Drawer, List, ListItem, ListItemIcon, ListItemText, Divider, Toolbar, Typography, Box, IconButton } from '@mui/material';
import { Home, Build, AccessTime, Book, Menu, Logout, Settings } from '@mui/icons-material';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const expandedDrawerWidth = 240;
const collapsedDrawerWidth = 60;

const Sidebar = ({ mobileOpen, handleDrawerToggle }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [expanded, setExpanded] = useState(true);

  const handleToggleSidebar = () => {
    setExpanded(!expanded);
  };

  const handleLogout = () => {
    // Perform logout operations, e.g., clear user data from storage
    localStorage.clear();
    sessionStorage.clear();
    // Redirect to the login screen
    navigate('/login');
  };

  const getSelectedStyle = (path) => {
    if (location.pathname === path) {
      return {
        backgroundColor: theme.palette.primary.main,
        color: '#fff',
        '& .MuiListItemIcon-root': {
          color: '#fff',
        },
      };
    }
    return null;
  };

  const drawer = (
    <div>
      <Toolbar>
        <Box display="flex" alignItems="center" justifyContent="center" width="100%">
          <img src="/logo192.png" alt="Logo" style={{ height: 40, marginRight: expanded ? theme.spacing(2) : 0 }} />
          {expanded && (
            <Typography variant="h6" noWrap component="div">
              Booking System
            </Typography>
          )}
        </Box>
      </Toolbar>
      <Divider />
      <List>
        <ListItem
          button
          component={Link}
          to="/"
          sx={getSelectedStyle('/')}
        >
          <ListItemIcon><Home /></ListItemIcon>
          {expanded && <ListItemText primary="Home" />}
        </ListItem>
        <ListItem
          button
          component={Link}
          to="/services"
          sx={getSelectedStyle('/services')}
        >
          <ListItemIcon><Build /></ListItemIcon>
          {expanded && <ListItemText primary="Services" />}
        </ListItem>
        <ListItem
          button
          component={Link}
          to="/schedule"
          sx={getSelectedStyle('/schedule')}
        >
          <ListItemIcon><AccessTime /></ListItemIcon>
          {expanded && <ListItemText primary="Schedule" />}
        </ListItem>
        <ListItem
          button
          component={Link}
          to="/bookings"
          sx={getSelectedStyle('/bookings')}
        >
          <ListItemIcon><Book /></ListItemIcon>
          {expanded && <ListItemText primary="Bookings" />}
        </ListItem>
        <Divider sx={{ mt: 2 }} />
        <ListItem
          button
          component={Link}
          to="/preferences"
          sx={getSelectedStyle('/preferences')}
        >
          <ListItemIcon><Settings /></ListItemIcon>
          {expanded && <ListItemText primary="Preferences" />}
        </ListItem>
        <ListItem
          button
          onClick={handleLogout}
          sx={{ mt: 2 }}
        >
          <ListItemIcon><Logout /></ListItemIcon>
          {expanded && <ListItemText primary="Logout" />}
        </ListItem>
      </List>
      <Box
        sx={{
          position: 'absolute',
          bottom: 0,
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          pb: 2,
        }}
      >
        <IconButton onClick={handleToggleSidebar}>
          <Menu />
        </IconButton>
      </Box>
    </div>
  );

  return (
    <div>
      {isMobile ? (
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: expanded ? expandedDrawerWidth : collapsedDrawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      ) : (
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: expanded ? expandedDrawerWidth : collapsedDrawerWidth,
              transition: 'width 0.3s',
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      )}
    </div>
  );
};

export default Sidebar;
