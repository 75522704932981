import React from 'react';
import { Calendar } from 'react-big-calendar';
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop';
import { momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css';

const localizer = momentLocalizer(moment);
const DragAndDropCalendar = withDragAndDrop(Calendar);

const ScheduleCalendar = ({ events, onSelectSlot, onEventResize, onDoubleClickEvent }) => (
  <DragAndDropCalendar
    localizer={localizer}
    events={events}
    selectable
    resizable
    defaultView="week"
    views={['week', 'day']}
    scrollToTime={new Date(1970, 1, 1, 9, 0, 0)}
    onSelectSlot={onSelectSlot}
    onEventResize={onEventResize}
    onDoubleClickEvent={onDoubleClickEvent}
    startAccessor="start"
    endAccessor="end"
    style={{ height: 700 }}
  />
);

export default ScheduleCalendar;
