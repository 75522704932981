import React, { useState } from 'react';
import { Box, Typography } from '@mui/material';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import CustomEvent from '../components/CustomEvent';
import ClientDialog from '../components/ClientDialog';

const localizer = momentLocalizer(moment);

const services = [
  { id: 1, name: 'Haircut', color: '#ff7f50' },
  { id: 2, name: 'Manicure', color: '#6495ed' },
  { id: 3, name: 'Massage', color: '#ff69b4' },
];

const bookings = [
  {
    id: 1,
    title: 'John Doe - Haircut',
    start: new Date(2024, 7, 9, 10, 0, 0), // August is month 7 (0-based index)
    end: new Date(2024, 7, 9, 11, 0, 0),
    clientName: 'John Doe',
    clientPhone: '123-456-7890',
    description: 'Regular haircut',
    serviceId: 1,
  },
  {
    id: 2,
    title: 'Jane Smith - Manicure',
    start: new Date(2024, 7, 8, 12, 0, 0),
    end: new Date(2024, 7, 8, 13, 0, 0),
    clientName: 'Jane Smith',
    clientPhone: '098-765-4321',
    description: 'Gel manicure',
    serviceId: 2,
  },
  {
    id: 3,
    title: 'Alice Johnson - Massage',
    start: new Date(2024, 7, 8, 14, 0, 0),
    end: new Date(2024, 7, 8, 15, 30, 0),
    clientName: 'Alice Johnson',
    clientPhone: '456-123-7890',
    description: 'Full body massage',
    serviceId: 3,
  },
];

const getEventStyle = (event) => {
  const service = services.find((service) => service.id === event.serviceId);
  return {
    style: {
      backgroundColor: service ? service.color : '#fff',
      color: '#fff',
      borderRadius: '4px',
      padding: '5px',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  };
};

const Bookings = () => {
  const [selectedBooking, setSelectedBooking] = useState(null);

  const handleSelectEvent = (event) => {
    setSelectedBooking(event);
  };

  const handleCloseDialog = () => {
    setSelectedBooking(null);
  };

  const handleCancelBooking = (booking) => {
    // Implement the logic to cancel the booking
    console.log('Cancel booking:', booking);
    handleCloseDialog();
  };

  const handleSendMessage = (booking) => {
    // Implement the logic to send a message to the client
    console.log('Send message to:', booking.clientPhone);
    handleCloseDialog();
  };

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h4" gutterBottom>Bookings</Typography>
      <Calendar
        localizer={localizer}
        events={bookings.map(booking => ({ ...booking, title: `${booking.clientName} - ${services.find(service => service.id === booking.serviceId)?.name}` }))}
        startAccessor="start"
        endAccessor="end"
        style={{ height: 700 }}
        views={['month', 'week', 'day', 'agenda']}
        defaultView="week"
        scrollToTime={new Date(1970, 1, 1, 9, 0, 0)}
        eventPropGetter={getEventStyle}
        onSelectEvent={handleSelectEvent}
        popup
        components={{
          event: (props) => <CustomEvent {...props} services={services} />,
        }}
      />
      <ClientDialog
        open={Boolean(selectedBooking)}
        booking={selectedBooking}
        services={services}
        onClose={handleCloseDialog}
        onCancelBooking={handleCancelBooking}
        onSendMessage={handleSendMessage}
      />
    </Box>
  );
};

export default Bookings;
