import React, { useState } from 'react';
import { Box, CssBaseline, IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { Outlet } from 'react-router-dom';
import Sidebar from './Sidebar';

const expandedDrawerWidth = 240;
const collapsedDrawerWidth = 60;

const Layout = () => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const [expanded, setExpanded] = useState(true);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleToggleSidebar = () => {
    setExpanded(!expanded);
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <Sidebar mobileOpen={mobileOpen} handleDrawerToggle={handleDrawerToggle} expanded={expanded} handleToggleSidebar={handleToggleSidebar} />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: { sm: `calc(100% - ${expanded ? expandedDrawerWidth : collapsedDrawerWidth}px)` },
          ml: { sm: `${expanded ? expandedDrawerWidth : collapsedDrawerWidth}px` },
          transition: 'width 0.3s, margin 0.3s',
        }}
      >
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerToggle}
          sx={{ display: { sm: 'none' } }}
        >
          <MenuIcon />
        </IconButton>
        <Outlet />
      </Box>
    </Box>
  );
};

export default Layout;
