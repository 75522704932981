import React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import theme from './theme';
import Layout from './components/Layout';
import Services from './pages/Services';
import Schedule from './pages/Schedule';
import Bookings from './pages/Bookings';
import Home from './pages/Home';
import Preferences from './pages/Preferences';
import Login from './pages/Login';
import Register from './pages/Register';
import Client from './pages/Client';
import PrivateRoute from './components/PrivateRoute';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/client" element={<Client />} />
          <Route
            path="/"
            element={
              <PrivateRoute>
                <Layout />
              </PrivateRoute>
            }
          >
            <Route index element={<Home />} />
            <Route path="services" element={<Services />} />
            <Route path="schedule" element={<Schedule />} />
            <Route path="bookings" element={<Bookings />} />
            <Route path="preferences" element={<Preferences />} />
          </Route>
        </Routes>
      </Router>
    </ThemeProvider>
  );
}

export default App;
