import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#6e3f98', // Custom primary color
    },
    secondary: {
      main: '#c8476f', // Custom secondary color
    },
    default: {
      main: '#9e9e9e', // Grey color for default buttons
    },
    background: {
      default: '#f5f5f5', // Background color
    },
  },
  components: {
    MuiDrawer: {
      styleOverrides: {
        paper: {
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
          background: 'linear-gradient(135deg, #b39ddb, #f48fb1)', // Lighter gradient colors
        },
      },
    },
  },
});

export default theme;
