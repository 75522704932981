import React, { useState, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import EventDialog from '../components/EventDialog';
import EventSnackbar from '../components/EventSnackbar';
import ScheduleCalendar from '../components/ScheduleCalendar';

const Schedule = () => {
  const [template, setTemplate] = useState([]);
  const [events, setEvents] = useState([]);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);

  useEffect(() => {
    fetchAvailabilities();
  }, []);

  const fetchAvailabilities = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/availabilities`);
      if (!response.ok) {
        throw new Error('Failed to fetch availabilities');
      }
      const data = await response.json();
      const events = data.map(item => ({
        id: item.id,
        title: 'Working Hours',
        start: calculateDateFromUtcDayCounterAndTime(item.utcDayCounter, item.startTime),
        end: calculateDateFromUtcDayCounterAndTime(item.utcDayCounter, item.endTime),
        status: item.status,
        createdAt: new Date(item.createdAt),
        dayOfWeek: calculateDayOfWeek(item.utcDayCounter),
      }));
      setEvents(events);
    } catch (error) {
      console.error('Error fetching availabilities:', error);
      showSnackbar('Error fetching availabilities');
    }
  };

  const calculateDateFromUtcDayCounterAndTime = (utcDayCounter, time) => {
    const epochDate = new Date(Date.UTC(1970, 0, 1));
    const targetDate = new Date(epochDate.getTime() + utcDayCounter * 24 * 60 * 60 * 1000);
    const [hours, minutes, seconds] = time.split(':').map(Number);

    targetDate.setUTCHours(hours, minutes, seconds, 0);

    // Adjust to local timezone without changing the time
    const localDate = new Date(targetDate.getTime() + (new Date().getTimezoneOffset() * 60000));

    return localDate;
  };

  const calculateDayOfWeek = (utcDayCounter) => {
    const epochDate = new Date(Date.UTC(1970, 0, 1));
    const targetDate = new Date(epochDate.getTime() + utcDayCounter * 24 * 60 * 60 * 1000);
    return targetDate.getUTCDay();
  };

  const handleSelectSlot = async ({ start, end }) => {
    const newEvent = {
      id: `event-${events.length}`,
      title: 'Working Hours',
      start,
      end,
      dayOfWeek: start.getUTCDay(),
      status: 'free',
      workerId: '68da8091-e42f-472c-82a0-ada60a7514c8', // Replace with actual workerId
    };

    try {
      const createDto = {
        workerId: newEvent.workerId,
        startTime: newEvent.start.toISOString(),
        endTime: newEvent.end.toISOString(),
        utcDayCounter: Math.floor((newEvent.start.getTime() - new Date(Date.UTC(1970, 0, 1)).getTime()) / (1000 * 60 * 60 * 24)),
        status: newEvent.status,
      };

      const response = await fetch(`${process.env.REACT_APP_API_URL}/availabilities`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
        body: JSON.stringify(createDto),
      });

      if (!response.ok) {
        throw new Error('Failed to create availability');
      }

      const savedEvent = await response.json();
      newEvent.id = savedEvent.id;
      setEvents([...events, newEvent]);
      showSnackbar('Time slot added successfully.');
    } catch (error) {
      console.error('Error creating availability:', error);
      showSnackbar('Error creating availability');
    }
  };

  const handleEventResize = ({ event, start, end }) => {
    const updatedEvent = { ...event, start, end };
    updatedEvent.workerId = '68da8091-e42f-472c-82a0-ada60a7514c8';//replace 

    const updatedEvents = events.map(e => (e.id === updatedEvent.id ? updatedEvent : e));

    setEvents(updatedEvents);
    updateEvent(updatedEvent);
    showSnackbar('Time slot resized successfully.');
  };

  const handleDoubleClickEvent = (event) => {
    setSelectedEvent(event);
    setOpenDeleteDialog(true);
  };

  const confirmDeleteEvent = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/availabilities/${selectedEvent.id}`, {
        method: 'DELETE',
        headers: {
          'Accept': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error('Failed to delete availability');
      }

      setEvents(events.filter(event => event.id !== selectedEvent.id));
      showSnackbar('Time slot deleted successfully.');
      setOpenDeleteDialog(false);
      setSelectedEvent(null);
    } catch (error) {
      console.error('Error deleting availability:', error);
      showSnackbar('Error deleting availability');
    }
  };

  const showSnackbar = (message) => {
    setSnackbarMessage(message);
    setOpenSnackbar(true);
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
    setSelectedEvent(null);
  };

  const updateEvent = async (event) => {
    try {
      const updateDto = {
        id: event.id,
        workerId:  '68da8091-e42f-472c-82a0-ada60a7514c8', // Replace with actual workerId
        startTime: event.start.toISOString(),
        endTime: event.end.toISOString(),
        utcDayCounter: Math.floor((event.start.getTime() - new Date(Date.UTC(1970, 0, 1)).getTime()) / (1000 * 60 * 60 * 24)),
        status: event.status
      };
      console.log('Updating event:', updateDto); // Debug log

      const response = await fetch(`${process.env.REACT_APP_API_URL}/availabilities/${event.id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
        body: JSON.stringify(updateDto),
      });

      if (!response.ok) {
        const errorText = await response.text();
        console.error('Response error text:', errorText); // Debug log
        throw new Error('Failed to update availability');
      }
      showSnackbar('Time slot updated successfully.');
    } catch (error) {
      console.error('Error updating availability:', error);
      showSnackbar('Error updating availability');
    }
  };

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h4" gutterBottom>Schedule</Typography>
      <Box sx={{ mt: 4 }}>
        <ScheduleCalendar
          events={events}
          onSelectSlot={handleSelectSlot}
          onEventResize={(e) => {
            handleEventResize(e);
            updateEvent({ ...e.event, start: e.start, end: e.end });
          }}
          onDoubleClickEvent={handleDoubleClickEvent}
        />
      </Box>
      <EventDialog
        open={openDeleteDialog}
        onClose={handleCloseDeleteDialog}
        onConfirm={confirmDeleteEvent}
      />
      <EventSnackbar
        open={openSnackbar}
        message={snackbarMessage}
        onClose={handleCloseSnackbar}
      />
    </Box>
  );
};

export default Schedule;
