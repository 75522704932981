import React, { useState } from 'react';
import { Box, Typography, Button, TextField, Grid, Snackbar, Alert } from '@mui/material';

const WorkingTemplateForm = ({ onSave }) => {
  const [template, setTemplate] = useState([
    { day: 'Monday', start: '09:00', end: '17:00' },
    { day: 'Tuesday', start: '09:00', end: '17:00' },
    { day: 'Wednesday', start: '09:00', end: '17:00' },
    { day: 'Thursday', start: '09:00', end: '17:00' },
    { day: 'Friday', start: '09:00', end: '17:00' },
    { day: 'Saturday', start: '', end: '' },
    { day: 'Sunday', start: '', end: '' },
  ]);

  const [loading, setLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('info');

  const handleChange = (index, field, value) => {
    const newTemplate = [...template];
    newTemplate[index][field] = value;
    setTemplate(newTemplate);
  };

  const calculateUtcDayCounter = (date) => {
    const epochDate = new Date('1970-01-01T00:00:00Z');
    const diffInMs = date.getTime() - epochDate.getTime();
    return Math.floor(diffInMs / (1000 * 60 * 60 * 24));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    onSave(template);

    setLoading(true);
    setSnackbarMessage('Please wait while the schedule is being updated...');
    setSnackbarSeverity('info');
    setSnackbarOpen(true);

    const occurrences = [];
    const startDate = new Date();

    for (let i = 0; i < 365; i++) {
      const currentDate = new Date(startDate.getTime());
      currentDate.setDate(startDate.getDate() + i);

      const dayTemplate = template.find(t => t.day === currentDate.toLocaleDateString('en-US', { weekday: 'long' }));

      if (dayTemplate && dayTemplate.start && dayTemplate.end) {
        const [startHours, startMinutes] = dayTemplate.start.split(':');
        const [endHours, endMinutes] = dayTemplate.end.split(':');

        const startTime = new Date(currentDate.getTime());
        startTime.setHours(startHours, startMinutes, 0, 0);

        const endTime = new Date(currentDate.getTime());
        endTime.setHours(endHours, endMinutes, 0, 0);

        const utcDayCounter = calculateUtcDayCounter(currentDate);

        occurrences.push({
          workerId: '68da8091-e42f-472c-82a0-ada60a7514c8', // Replace with the actual worker ID as needed
          startTime: startTime.toISOString(),
          endTime: endTime.toISOString(),
          utcDayCounter,
          status: 'free',
        });
      }
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/availabilities/buildSchedule/68da8091-e42f-472c-82a0-ada60a7514c8`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
        body: JSON.stringify(occurrences),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      setSnackbarMessage('Schedule created successfully');
      setSnackbarSeverity('success');
    } catch (error) {
      console.error('Error creating schedule', error);
      setSnackbarMessage('Error creating schedule');
      setSnackbarSeverity('error');
    } finally {
      setLoading(false);
      setSnackbarOpen(true);
    }
  };

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h5" gutterBottom>Define Working Template</Typography>
      <Typography variant="body2" gutterBottom>This will save occurrences and overwrite them for the next 365 days</Typography>
      <Box sx={{ p: 3 }}>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            {template.map((day, index) => (
              <React.Fragment key={index}>
                <Grid item xs={3}>
                  <Typography>{day.day}</Typography>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    type="time"
                    label="Start Time"
                    value={day.start}
                    onChange={(e) => handleChange(index, 'start', e.target.value)}
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    type="time"
                    label="End Time"
                    value={day.end}
                    onChange={(e) => handleChange(index, 'end', e.target.value)}
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
              </React.Fragment>
            ))}
            <Grid item xs={12}>
              <Button type="submit" variant="contained" color="primary" disabled={loading}>
                Save Template
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>
      <Snackbar
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        autoHideDuration={6000}
      >
        <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default WorkingTemplateForm;
