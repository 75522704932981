import React, { useState } from 'react';
import { Box, Button, TextField, Typography, Paper, Avatar, Container, CssBaseline, Link } from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import GoogleIcon from '@mui/icons-material/Google';
import { useNavigate } from 'react-router-dom';
import bcrypt from 'bcryptjs';


const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const handleLogin = async () => {
    try {
      console.log('Logging in with username:', username, 'and password:', password);
      console.log("process.env.REACT_APP_HASH_SALT", `$2a$10$.${process.env.REACT_APP_HASH_SALT}`)
      const hashedPassword = await bcrypt.hash(password, `$2a$10$.${process.env.REACT_APP_HASH_SALT}`);

      const response = await fetch(`${process.env.REACT_APP_IDENTITY_API_URL}/auth/wld/login`, {
        method: 'POST', // Specify the method
        headers: {
          'Content-Type': 'application/json', // Specify the content type
        },
        body: JSON.stringify({ username: username, password: hashedPassword }), // Convert the username and password to a JSON string
      });

      if (!response.ok) {
        // If the response was not OK, throw an error
        throw new Error(`Error: ${response.statusText}`);
      }

      const data = await response.json(); // Parse the JSON response
      console.log('Login successful:', data);

      // Handle the successful login, e.g., store the token, navigate, etc.
      localStorage.setItem('isAuthenticated', 'true');
      localStorage.setItem('token', data.access_token)
      navigate('/');
    } catch (error) {
      console.error('Login failed:', error);
      // Handle the error, e.g., show an error message
      alert('Login failed!');
    }
  };

  const handleGoogleLogin = async () => {
    // Handle Google login logic here api/auth/google 
    try {
      const response = await fetch(`${process.env.REACT_APP_IDENTITY_API_URL}/auth/google`, {
        method: 'GET', // Specify the method
        headers: {
          'Content-Type': 'application/json', // Specify the content type
        },
      });

      if (!response.ok) {
        // If the response was not OK, throw an error
        throw new Error(`Error: ${response.statusText}`);
      }

      const data = await response.json(); // Parse the JSON response
      console.log('Login successful:', data);

      // Handle the successful login, e.g., store the token, navigate, etc.
      localStorage.setItem('isAuthenticated', 'true');
      localStorage.setItem('token', data.access_token)
      navigate('/');
    } catch (error) {
      console.error('Login failed:', error);
      // Handle the error, e.g., show an error message
      alert('Login via google failed!');
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Paper sx={{ mt: 8, p: 4, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Login
        </Typography>
        <Box component="form" sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="username"
            label="Username"
            name="username"
            autoComplete="username"
            autoFocus
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <Button
            type="button"
            fullWidth
            variant="contained"
            color="primary"
            sx={{ mt: 3, mb: 2 }}
            onClick={handleLogin}
          >
            Login
          </Button>
          <Button
            type="button"
            fullWidth
            variant="outlined"
            color="primary"
            startIcon={<GoogleIcon />}
            sx={{ mb: 2 }}
            onClick={handleGoogleLogin}
          >
            Login with Google
          </Button>
          <Box sx={{ mt: 2 }}>
            <Link href="/register" variant="body2">
              {"Don't have an account? Register"}
            </Link>
          </Box>
        </Box>
      </Paper>
    </Container>
  );
};

export default Login;
