import React, { useState, useEffect } from 'react';
import { Box, Typography, Button, Grid, Card, CardContent, CardActions, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

const fetchServices = async () => {
  const response = await fetch(`${process.env.REACT_APP_API_URL}/products`);
  if (!response.ok) {
    throw new Error('Failed to fetch services');
  }
  const data = await response.json();
  return data;
};

const addService = async (service) => {
  service.duration = parseInt(service.duration);
  service.price = parseFloat(service.price);

  const response = await fetch(`${process.env.REACT_APP_API_URL}/products`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(service),
  });
  return response.json();
};

const editService = async (id, service) => {
  service.duration = parseInt(service.duration);
  service.price = parseFloat(service.price);

  const response = await fetch(`${process.env.REACT_APP_API_URL}/products/${id}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(service),
  });
  return response.json();
};

const deleteService = async (id) => {
  const response = await fetch(`${process.env.REACT_APP_API_URL}/products/${id}`, {
    method: 'DELETE',
  });
  return response.json();
};

const Services = () => {
  const [services, setServices] = useState([]);
  const [open, setOpen] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [currentService, setCurrentService] = useState({ id: '', name: '', price: '', duration: '', description: '' });

  useEffect(() => {
    const getServices = async () => {
      try {
        const services = await fetchServices();
        setServices(services);
      } catch (error) {
        console.error(error);
      }
    };
    getServices();
  }, []);

  const handleClickOpen = () => {
    setCurrentService({ name: '', price: '', duration: '', description: '' });
    setEditMode(false);
    setOpen(true);
  };

  const handleEditOpen = (service) => {
    setCurrentService(service);
    setEditMode(true);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = async () => {
    try {
      if (editMode) {
        await editService(currentService.id, currentService);
      } else {
        await addService(currentService);
      }
      const services = await fetchServices();
      setServices(services);
      handleClose();
    } catch (error) {
      console.error(error);
    }
  };

  const handleDelete = async (id) => {
    try {
      await deleteService(id);
      const services = await fetchServices();
      setServices(services);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h4" gutterBottom>Services</Typography>
      <Button variant="contained" color="primary" startIcon={<AddIcon />} onClick={handleClickOpen}>
        Add Service
      </Button>
      <Grid container spacing={2} sx={{ mt: 3 }}>
        {services.map((service) => (
          <Grid item xs={12} md={6} lg={4} key={service.id}>
            <Card>
              <CardContent>
                <Typography variant="h5">{service.name}</Typography>
                <Typography>Description: {service.description}</Typography>
                <Typography>Duration: {service.duration} mins</Typography>
                <Typography>Price: ${service.price}</Typography>
              </CardContent>
              <CardActions>
                <Button size="small" color="primary" startIcon={<EditIcon />} onClick={() => handleEditOpen(service)}>
                  Edit
                </Button>
                <Button size="small" color="secondary" startIcon={<DeleteIcon />} onClick={() => handleDelete(service.id)}>
                  Delete
                </Button>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{editMode ? 'Edit Service' : 'Add Service'}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {editMode ? 'Edit the details of the service.' : 'Enter the details of the new service.'}
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Service Name"
            type="text"
            fullWidth
            variant="outlined"
            value={currentService.name}
            onChange={(e) => setCurrentService({ ...currentService, name: e.target.value })}
          />
          <TextField
            margin="dense"
            id="description"
            label="Description"
            type="text"
            fullWidth
            variant="outlined"
            value={currentService.description}
            onChange={(e) => setCurrentService({ ...currentService, description: e.target.value })}
          />
          <TextField
            margin="dense"
            id="duration"
            label="Duration (mins)"
            type="number"
            fullWidth
            variant="outlined"
            value={currentService.duration}
            onChange={(e) => setCurrentService({ ...currentService, duration: e.target.value })}
          />
          <TextField
            margin="dense"
            id="price"
            label="Price ($)"
            type="number"
            fullWidth
            variant="outlined"
            value={currentService.price}
            onChange={(e) => setCurrentService({ ...currentService, price: e.target.value })}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">Cancel</Button>
          <Button onClick={handleSave} color="primary">{editMode ? 'Save' : 'Add'}</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Services;
