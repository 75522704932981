import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@mui/material';
import moment from 'moment';

const ClientDialog = ({ open, booking, services, onClose, onCancelBooking, onSendMessage }) => {
  if (!booking) return null;

  const service = services.find(service => service.id === booking.serviceId);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Booking Details</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <strong>Client Name:</strong> {booking.clientName}
        </DialogContentText>
        <DialogContentText>
          <strong>Client Phone:</strong> {booking.clientPhone}
        </DialogContentText>
        <DialogContentText>
          <strong>Description:</strong> {booking.description}
        </DialogContentText>
        <DialogContentText>
          <strong>Service:</strong> {service ? service.name : ''}
        </DialogContentText>
        <DialogContentText>
          <strong>Start:</strong> {moment(booking.start).format('MMMM Do YYYY, h:mm a')}
        </DialogContentText>
        <DialogContentText>
          <strong>End:</strong> {moment(booking.end).format('MMMM Do YYYY, h:mm a')}
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'space-between', padding: '16px' }}>
        <Button onClick={() => onSendMessage(booking)} variant="contained" color="primary">Send Message</Button>
        <Button onClick={() => onCancelBooking(booking)} variant="contained" color="secondary">Cancel Booking</Button>
        <Button onClick={onClose} variant="contained" color="default">Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ClientDialog;
